import { NextSeo } from 'next-seo';
import { useTranslation } from 'react-i18next';

import { canonicalLink, languageAlternatesLinks } from 'utils/seo';
import LoginContainer from 'containers/LoginContainer';

import { LINKS } from 'constants/constants';
import { GetStaticProps } from 'next';
import i18n from 'config/i18n';
import { useRouter } from 'next/router';

export const getStaticProps: GetStaticProps = async (props) => {
  await i18n.changeLanguage(props.locale);

  return {
    props,
  };
};

export default function LoginPage() {
  const { t } = useTranslation();
  const { locales } = useRouter();
  return (
    <>
      <NextSeo
        title={t('qr.page.landing.seo.title')}
        description={t('qr.page.landing.seo.description')}
        languageAlternates={languageAlternatesLinks(LINKS.LOGIN, locales)}
        canonical={canonicalLink(LINKS.LOGIN, false, i18n.language)}
      />
      <LoginContainer />
    </>
  );
}
